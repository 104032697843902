import { models, addModel } from './state';

const templates = {
    collection: {
        initialState: {
            loaded: false,
            list: [],
            selected: null,
        },
        actions: {
            load: (list) => {
                return {
                    list,
                };
            },
            select: (id) => {
                return {
                    id,
                };
            },
        },
        reducer: (state, action) => {
            switch (action.type) {
                case 'load': {
                    const { list } = action;
                    return {
                        ...state,
                        loaded: true,
                        list,
                    };
                }

                case 'select': {
                    const { id } = action;
                    return {
                        ...state,
                        selected: state.list.find((x) => x.id === id) || null,
                    };
                }

                default: return state;
            }
        },
    },
};

const registerModel = (name, template = null, initialState = null, actions = {}, reducer = null) => {
    if (models[name]) {
        console.log(`[Store]: Model "${name}" already registered. Overwriting was not allowed.`);
        return null;
    }

    if (template !== null) {
        if (!templates[template]) {
            console.log(`[Store]: Model "${name}" can not registered with "${template}" template. Deny.`);
            return null;
        }

        const finalState = initialState !== null ? initialState : templates[template].initialState;
        const finalActions = Object.keys(actions).length !== 0 ? actions : templates[template].actions;
        const finalReducer = reducer !== null ? reducer : templates[template].reducer;

        addModel(name, finalState, finalActions, finalReducer);
        return null;
    }

    if (Object.keys(actions).length === 0) {
        console.log(`[Store]: Model "${name}" has not actions. Deny.`);
    }

    if (reducer === null) {
        console.log(`[Store]: Model "${name}" has not reducer. Deny.`);
    }

    addModel(name, initialState, actions, reducer);
    return null;
};

export default registerModel;
