import { useState, useCallback } from 'react';

const useForm = (defaultState = {}) => {
    const [state, setState] = useState(defaultState);

    const createField = useCallback((field, type = 'text', required = false, placeholder = '', validator = null) => {
        let value = '';
        switch (type) {
            case 'text': {
                value = placeholder;
                break;
            }

            case 'number': {
                value = placeholder;
                break;
            }

            case 'email': {
                value = placeholder;
                break;
            }

            case 'checkbox': {
                value = false;
                break;
            }

            case 'array': {
                value = [];
                break;
            }

            default: {
                value = '';
                break;
            }
        }

        return setState((prev) => ({
            ...prev,
            [field]: {
                type,
                value,
                required,
                status: 'default',
                validator,
            },
        }));
    }, [state]);

    const changeValue = useCallback((field, value, type = 'push') => {
        switch (state[field].type) {
            case 'checkbox': {
                return setState((prev) => ({
                    ...prev,
                    [field]: {
                        ...prev[field],
                        value: !prev[field],
                    },
                }));
            }

            case 'array': {
                let updatedValue = state[field].value;
                if (type === 'push') {
                    updatedValue = [...updatedValue, value];
                }

                if (type === 'pushToLeft') {
                    updatedValue = [value, ...updatedValue];
                }

                if (type === 'remove') {
                    updatedValue = updatedValue.filter((x) => x !== value);
                }

                return setState((prev) => ({
                    ...prev,
                    [field]: {
                        ...prev[field],
                        updatedValue,
                    },
                }));
            }

            default: {
                return setState((prev) => ({
                    ...prev,
                    [field]: {
                        ...prev[field],
                        value,
                    },
                }));
            }
        }
    }, [state]);

    const validateForm = useCallback((onSuccess = null, onError = null) => {
        const errors = [];

        // Check for errors
        Object.keys(state).forEach((key) => {
            const field = state[key];
            if (field.validator !== null) {
                if (!field.validator(field.value)) {
                    errors.push(key);
                }
            } else {
                switch (field.type) {
                    case 'text': {
                        if (field.value === '') {
                            errors.push(key);
                        }

                        break;
                    }

                    case 'number': {
                        if (isNaN(Number(field.value))) {
                            errors.push(key);
                        }

                        break;
                    }

                    case 'email': {
                        const re = /\S+@\S+\.\S+/;
                        if (!re.test(field.value)) {
                            errors.push(key);
                        }

                        break;
                    }
                }
            }
        });

        // update statuses
        setState((prev) => {
            let newState = { ...prev };
            Object.keys(state).forEach((key) => {
                if (errors.includes(key)) {
                    if (!newState[key].required && !newState[key].value) {
                        newState[key].status = 'valid';
                    } else {
                        newState[key].status = 'error';
                    }
                } else {
                    newState[key].status = 'valid';
                }
            });

            return newState;
        });

        if (errors.length > 0) {
            if (onError !== null) {
                return onError(errors);
            }
        } else {
            if (onSuccess !== null) {
                return onSuccess(Object.keys(state).reduce((a, x) => {
                    a[x] = state[x].value;
                    return a;
                }, {}));
            }
        }
    }, [state]);

    return [
        {
            state,
            createField,
            changeValue,
        },
        validateForm,
    ];
};

export default useForm;
