import request from 'request-promise';
import * as UUID from 'uuid';

let settings = {
    production: null,
    development: null,
};

export const init = (_production, _development = null) => {
    settings.production = _production;
    settings.development = _development;
};

export const call = (method, params) => {
    let url = settings.production;
    params = {
        app_token: btoa(window.location.search.slice(1, window.location.search.length + 1)),
        ...params,
    };

    if (process.env.NODE_ENV !== 'production' && settings.development !== null) {
        url = settings.development;
    }

    if (url === null) {
        return console.log(`[Network]: Can't call method "${method}" because url not set.`);
    }

    return new Promise((resolve, reject) => {
        request({
            method: 'POST',
            uri: url,
            json: true,
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                id: UUID.v4(),
                jsonrpc: '2.0',
                method: method,
                params: params,
            },
        })
            .then((response) => {
                if (response.error) {
                    return reject({
                        message: response.error.message,
                        code: response.error.code,
                        data: response.error.data,
                    });
                }

                return resolve(response.result);
            })
            .catch((err) => reject(err));
    });
};
