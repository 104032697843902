import GA from 'react-ga';
import parseParams from '../utils/parseParams';

export const init = (trackingCode = '') => {
    const params = parseParams(window.location.search);
    GA.initialize(trackingCode, {
        gaOptions: {
            userId: (params && params.vk_user_id) || 'no-user',
        },
    });
};

export const sendEvent = (category = '', action = '', label = '', value = 0) => {
    GA.event({
        category,
        action,
        label,
        value,
    });
};

export const sendPath = (path) => {
    GA.pageview(path);
};
