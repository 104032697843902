import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import bridge from '@vkontakte/vk-bridge';
import {
    Root,
    ConfigProvider,
    ScreenSpinner,
    Alert,
    ModalRoot,
    ModalPageHeader,
    PanelHeaderButton,
    IS_PLATFORM_ANDROID,
    IS_PLATFORM_IOS,
} from '@vkontakte/vkui';

import analytics from '../analytics';
import utils from '../utils';

import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';

const ANALYTICS_CATEGORY = 'Router';
const ANALYTICS_INITIAL_PARAMS = 'Initial Params';
const ANALYTICS_INITIAL_REF = 'Initial Ref';
const ANALYTICS_INITIAL_GROUP = 'Initial Group';
const ANALYTICS_PLATFORM = 'Platform';
const ANALYTICS_ACTION_OPEN_PAGE = 'Open Page';
const ANALYTICS_ACTION_OPEN_TAB = 'Open Tab';
const ANALYTICS_ACTION_OPEN_PANEL = 'Open Panel';
const ANALYTICS_ACTION_OPEN_MODAL = 'Open Modal';
const ANALYTICS_ACTION_OPEN_POPOUT = 'Open Popout';

const Router = ({ initialPage, modals, children, debug }) => {
    const [state, setState] = useState({});
    const [activePage, setActivePage] = useState(initialPage);
    const [activeModal, setActiveModal] = useState(null);
    const [history, setHistory] = useState([]);
    const [modalHistory, setModalHistory] = useState([]);
    const [popout, setPopout] = useState(null);
    const [prevPath, setPrevPath] = useState('');

    const getPageState = useCallback((page, activePanel, isTab, transport, callback) => {
        if (!state[page]) {
            setState((prev) => ({
                ...prev,
                [page]: {
                    initialPanel: activePanel,
                    activePanel,
                    history: [activePanel],
                    transport,
                },
            }));

            if (history.indexOf(page) === -1 && !isTab) {
                setHistory((prev) => [...prev, page]);
            }

            return callback({
                activePanel,
                history: [activePanel],
            });
        }

        setState((prev) => ({
            ...prev,
            [page]: {
                ...prev[page],
                transport,
            },
        }));

        callback({
            activePanel: state[page].activePanel,
            history: state[page].history,
        });
    }, [state, history]);

    const openPage = useCallback((nextPage, reset = false) => {
        analytics.sendEvent(ANALYTICS_CATEGORY, ANALYTICS_ACTION_OPEN_PAGE, nextPage);

        if (reset) {
            window.history.replaceState({ page: nextPage }, '');
            bridge.send('VKWebAppDisableSwipeBack', {});
            setActivePage(nextPage);
            setHistory(() => [nextPage]);

            return;
        }

        window.history.pushState({ page: nextPage }, '');
        setActivePage(nextPage);
        setHistory((prev) => [...prev, nextPage]);
    }, []);

    const openTab = useCallback((nextTab) => {
        analytics.sendEvent(ANALYTICS_CATEGORY, ANALYTICS_ACTION_OPEN_TAB, nextTab);

        window.history.replaceState({ tab: nextTab }, '');

        const updatedPage = {
            ...state[activePage],
            activePanel: nextTab,
            history: [nextTab],
        };

        setState((prev) => ({
            ...prev,
            [activePage]: updatedPage,
        }));

        if (state[activePage].transport) {
            state[activePage].transport({
                activePanel: updatedPage.activePanel,
                history: updatedPage.history,
            });
        }

    }, [state, activePage]);

    const openPanel = useCallback((nextPanel, reset = false) => {
        analytics.sendEvent(ANALYTICS_CATEGORY, ANALYTICS_ACTION_OPEN_PANEL, nextPanel);

        if (reset) {
            window.history.replaceState({ panel: nextPanel }, '');
            bridge.send('VKWebAppDisableSwipeBack', {});

            const updatedPage = {
                ...state[activePage],
                activePanel: nextPanel,
                history: [nextPanel],
            };

            setState((prev) => ({
                ...prev,
                [activePage]: updatedPage,
            }));

            if (state[activePage].transport) {
                state[activePage].transport({
                    activePanel: updatedPage.activePanel,
                    history: updatedPage.history,
                });
            }

            return;
        }

        window.history.pushState({ panel: nextPanel }, '');

        if (state[activePage].activePanel === state[activePage].initialPanel) {
            bridge.send('VKWebAppEnableSwipeBack', {});
        }

        const updatedPage = {
            ...state[activePage],
            activePanel: nextPanel,
            history: [...state[activePage].history, nextPanel],
        };

        setState((prev) => ({
            ...prev,
            [activePage]: updatedPage,
        }));

        if (state[activePage].transport) {
            state[activePage].transport({
                activePanel: updatedPage.activePanel,
                history: updatedPage.history,
            });
        }
    }, [state]);

    const openModal = useCallback((nextModal) => {
        analytics.sendEvent(ANALYTICS_CATEGORY, ANALYTICS_ACTION_OPEN_MODAL, nextModal);

        if (activeModal === null) {
            bridge.send('VKWebAppEnableSwipeBack', {});
        }

        window.history.pushState({ modal: nextModal }, '');
        setActiveModal(nextModal);
        setModalHistory((prev) => [...prev, nextModal]);
    }, [activeModal]);

    const openPopout = useCallback((nextPopout) => {
        analytics.sendEvent(ANALYTICS_CATEGORY, ANALYTICS_ACTION_OPEN_POPOUT);

        setPopout(nextPopout);
    }, []);

    const goBack = useCallback(() => {
        if (popout !== null) {
            return;
        }

        if (modalHistory.length >= 1) {
            if (modalHistory.length === 1) {
                bridge.send('VKWebAppDisableSwipeBack', {});
                setActiveModal(null);
                setModalHistory([]);
            } else {
                setActiveModal(modalHistory[modalHistory.length - 2]);
                setModalHistory(modalHistory.slice(0, modalHistory.length - 1));
            }

            return;
        }

        if (state[activePage].history.length > 1) {
            const updatedPage = {
                ...state[activePage],
                activePanel: state[activePage].history[state[activePage].history.length - 2],
                history: state[activePage].history.slice(0, state[activePage].history.length - 1),
            };

            setState((prev) => ({
                ...prev,
                [activePage]: updatedPage,
            }));

            if (updatedPage.history.length === 1) {
                bridge.send('VKWebAppDisableSwipeBack', {});
            }

            if (state[activePage].transport) {
                state[activePage].transport({
                    activePanel: updatedPage.activePanel,
                    history: updatedPage.history,
                });
            }

            return;
        }

        if (history.length > 1) {
            setActivePage(history[history.length - 2]);
            setHistory((prev) => prev.slice(0, prev.length - 1));
        }

        // return bridge.send('VKWebAppClose', { status: 'success' });
    }, [state, popout, activePage, history, modalHistory]);

    const showLoader = () => openPopout(<ScreenSpinner />);
    const hideLoader = () => setPopout(null);

    const showAlert = (title, description, actions = [], actionsLayout = 'horizontal') => {
        openPopout(
            <Alert
                actionsLayout={actionsLayout}
                actions={
                    actions.length === 0 ? [
                        {
                            title: 'Закрыть',
                            autoclose: true,
                            mode: 'cancel',
                        },
                    ] : actions
                }
                onClose={() => setPopout(null)}
            >
                <h2>{title}</h2>
                <p>{description}</p>
            </Alert>
        );
    };

    const router = {
        state,
        getPageState,
        openPage,
        openTab,
        openPanel,
        openModal,
        openPopout,
        showLoader,
        hideLoader,
        showAlert,
        goBack,
    };

    useEffect(() => {
        // hash
        let hash = window.location.hash;
        if (hash.length > 0) {
            hash = window.location.hash.slice(1, window.location.hash.length);
            analytics.sendEvent(ANALYTICS_CATEGORY, ANALYTICS_INITIAL_PARAMS, hash);
        }

        // params
        const params = utils.parseParams(window.location.search);
        if (params.vk_ref) {
            analytics.sendEvent(ANALYTICS_CATEGORY, ANALYTICS_INITIAL_REF, params.vk_ref);
        }

        if (params.vk_group_id) {
            analytics.sendEvent(ANALYTICS_CATEGORY, ANALYTICS_INITIAL_GROUP, params.vk_group_id);
        }

        if (params.vk_platform) {
            analytics.sendEvent(ANALYTICS_CATEGORY, ANALYTICS_PLATFORM, params.vk_platform);
        }
    }, []);

    useEffect(() => {
        if (debug) {
            console.log(state, history, modalHistory);
        }
    }, [state, history, modalHistory]);

    useEffect(() => {
        window.addEventListener('popstate', goBack);
        return () => {
            window.removeEventListener('popstate', goBack);
        };
    }, [goBack]);

    useEffect(() => {
        if (state[activePage]) {
            const newPath = `/${activePage}/${state[activePage].activePanel}${activeModal !== null ? `#${activeModal}` : ''}`;
            if (newPath !== prevPath) {
                analytics.sendPath(newPath);
                setPrevPath(newPath);
            }
        }
    }, [state, activeModal, activePage, prevPath]);

    const prepareModal = () => {
        if (modals.length === 0) {
            return null;
        }

        return (
            <ModalRoot
                activeModal={activeModal}
                onClose={goBack}
            >
                {modals.map((modal) => React.cloneElement(
                    modal,
                    {
                        ...modal.props,
                        key: modal.props.id,
                        header: modal.props.title ? (
                            <ModalPageHeader
                                left={IS_PLATFORM_ANDROID && <PanelHeaderButton onClick={goBack}><Icon24Cancel /></PanelHeaderButton>}
                                right={IS_PLATFORM_IOS && <PanelHeaderButton onClick={goBack}><Icon24Dismiss /></PanelHeaderButton>}
                            >
                                {modal.props.title}
                            </ModalPageHeader>
                        ) : null,
                        router,
                    }
                ))}
            </ModalRoot>
        );
    };

    return (
        <ConfigProvider>
            <Root activeView={activePage} popout={popout} modal={prepareModal()}>
                {React.Children.map(children, (child) => React.cloneElement(
                    child,
                    {
                        ...child.props,
                        key: child.props.id,
                        router,
                    }
                ))}
            </Root>
        </ConfigProvider>
    );
};

Router.propTypes = {
    initialPage: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    modals: PropTypes.array,
    debug: PropTypes.bool,
};

Router.defaultProps = {
    debug: false,
    modals: [],
};

export default Router;
