import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@vkontakte/vkui';

const TextInput = ({ form, name, type, required, validator, className, placeholder }) => {
    useEffect(() => {
        if (!form.state[name]) {
            form.createField(name, type, required, placeholder, validator);
        }
    }, []);

    return (
        <Input
            className={className}
            value={(form.state[name] && form.state[name].value) || ''}
            status={(form.state[name] && form.state[name].status) || 'default'}
            onChange={(e) => {
                const value = e.currentTarget.value;
                form.changeValue(name, value);
            }}
        />
    );
};

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    required: PropTypes.bool,
    validator: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    form: PropTypes.shape({
        createField: PropTypes.func.isRequired,
        changeValue: PropTypes.func.isRequired,
        state: PropTypes.shape({
            [PropTypes.string]: {
                value: PropTypes.string,
                status: PropTypes.string,
            },
        }),
    }),
};

TextInput.defaultProps = {
    type: 'text',
    required: false,
    validator: null,
    className: '',
    placeholder: '',
};

export default TextInput;
