import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Epic, Tabbar as VKTabbar, TabbarItem } from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';

const Tabbar = ({ id, initialPanel, router, children, debug, ...rest }) => {
    const [activePanel, setActivePanel] = useState((router.state[id] && router.state[id].activePanel) || initialPanel);

    const onUpdate = ({ activePanel }) => {
        setActivePanel(activePanel);
    };

    useEffect(() => {
        if (debug) {
            console.log(activePanel, initialPanel);
        }
    }, [activePanel, initialPanel, debug]);

    useEffect(() => {
        router.getPageState(id, initialPanel, false, onUpdate, ({ activePanel }) => {
            setActivePanel(activePanel);
            bridge.send('VKWebAppDisableSwipeBack', {});
        });
    }, []);

    const generateTabbar = () => (
        <VKTabbar>
            {React.Children.map(children, (child) => {
                if (child.props.text || child.props.icon) {
                    return (
                        <TabbarItem
                            key={child.props.id}
                            selected={activePanel === child.props.id}
                            text={child.props.text || ''}
                            onClick={() => router.openTab(child.props.id)}
                        >
                            {child.props.icon}
                        </TabbarItem>
                    );
                }
            })}
        </VKTabbar>
    );

    return (
        <Epic
            id={id}
            activeStory={activePanel}
            tabbar={generateTabbar()}
            {...rest}
        >
            {React.Children.map(children, (child) => React.cloneElement(
                child,
                {
                    ...child.props,
                    isTab: true,
                    router,
                }
            ))}
        </Epic>
    );
};

Tabbar.propTypes = {
    id: PropTypes.string.isRequired,
    initialPanel: PropTypes.string.isRequired,
    router: PropTypes.any,
    debug: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

Tabbar.default = {
    debug: false,
};

export default Tabbar;
