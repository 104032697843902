const validateAppParams = (url = '', secret_key = '') => {
    const queryParams = url.split('&').reduce((a, x) => {
        const data = x.split('=');
        a[data[0]] = data[1];
        return a;
    }, {});

    const signParams = {};
    Object.keys(queryParams).sort()
        .forEach((key) => {
            if (!key.startsWith('vk_')) {
                return;
            }
            signParams[key] = queryParams[key];
        });

    const signStr = Object.keys(signParams).reduce((a, x) => {
        a.push(x + '=' + signParams[x]);
        return a;
    }, []).join('&');

    let sign = require('crypto').createHmac('sha256', secret_key).update(signStr);
    sign = sign.digest('binary');
    sign = require('buffer').Buffer.from(sign, 'binary').toString('base64');
    sign = sign.split('+').join('-');
    sign = sign.split('/').join('_');
    sign = sign.replace(/=+$/, '');

    return sign === queryParams['sign'] ? queryParams['vk_user_id'] : null;
};

export default validateAppParams;
